import { Center, Flex, HStack, ImageProps, Spinner, Text, VStack, WrapItem } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';

import ImageWithError from '../../../components/ImageWithError';
import InfoWithTooltip from '../../../components/InfoWithTooltip';
import ModelIdentityBanner from '../../../components/ModelIdentityBanner';
import ImageSlider from '../../../components/sliders/ImageSlider';
import OpacitySlider from '../../../components/sliders/OpacitySlider';
import { Sample } from '../../../types/api-types';
import { CROSSWARP_IMAGESIZE_KEY } from '../../../utils/constants';
import useFeedbackContextHelper from '../../../utils/feedback-context-helper-hook';
import { loadFromLocalStorage } from '../../../utils/local-storage-helpers';

const defaultProductsPerRow = 4;
const DEFAULT_OPACITY = 1;
const CustomLoader = <Center
    h="100%"
    position="absolute"
    top={0}
    width="100%"
    zIndex={1}
>
    <Spinner size="lg" />
</Center>;
const opacityElementDefaultProps: ImageProps = {
    fallback: CustomLoader,
    height: '100%',
    objectFit: 'contain',
    position: 'absolute',
    top: 0,
    width: '100%',
};

interface SampleGridGridElementProps {
    label?: string
    info?: string
    gridSample: Sample[]
    gridChangePage(): void
}

export default function SampleGridGridElement(props: SampleGridGridElementProps) {
    const { label, info, gridSample, gridChangePage } = props;

    useFeedbackContextHelper({ gridSample, label });

    const [productsPerRow, setProductsPerRow] = useState<number>(loadFromLocalStorage(CROSSWARP_IMAGESIZE_KEY) || defaultProductsPerRow);
    const [opacityValue, setOpacityValue] = useState<number>(DEFAULT_OPACITY);

    // ---- True if at least one sample has transparent, false otherwise ----
    const sampleHasTransparent = useMemo(() => {
        let result = false;

        for (let i = 0; i < gridSample.length; i++) {
            if (gridSample[i].transparent) {
                result = true;
                break;
            }
        }

        return result;
    }, [gridSample]);

    const handleOnScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const containerHeight = event.currentTarget.clientHeight;
        const { scrollHeight } = event.currentTarget;

        const { scrollTop } = event.currentTarget;
        const percentageScroll = ((scrollTop + containerHeight) / scrollHeight) * 100;

        if (percentageScroll < 80) {
            return;
        }

        gridChangePage();
    };

    return (
        <VStack w="100%">
            <HStack justifyContent="space-around" pl={4} pr={4} spacing={4} w="100%">
                {
                    label && <HStack flex={1} justifyContent="flex-start">
                        <Text fontWeight="bold">{label}</Text>
                        {info && <InfoWithTooltip tooltipLabel={info} />}
                    </HStack>
                }
                {
                    sampleHasTransparent && <OpacitySlider
                        defaultValue={DEFAULT_OPACITY}
                        onChange={setOpacityValue}
                    />
                }
                <ImageSlider
                    localStorageKey={CROSSWARP_IMAGESIZE_KEY}
                    max={5}
                    min={1}
                    onChange={setProductsPerRow}
                />
            </HStack>

            <HStack
                height="100%"
                justifyContent="center"
                onScroll={handleOnScroll}
                overflow="auto"
                spacing={0}
                w="100%"
            >
                <Flex as="ul" flexWrap="wrap" gridGap={2} height="100%" width="100%">
                    {
                        gridSample.map((sample, index) => (
                            <WrapItem
                                alignItems="center"
                                flexDirection="column"
                                key={sample.garment_id + sample.model_id + index}
                                w={`calc(100% / ${productsPerRow} - 8px)`}
                            >
                                {
                                    sample.transparent
                                        ? <HStack h={0} overflow="hidden" paddingBottom="100%" position="relative" w="100%">
                                            <ImageWithError
                                                {...opacityElementDefaultProps}
                                                fallback={CustomLoader}
                                                opacity={opacityValue}
                                                src={sample.transparent.garment_image_url}
                                                zIndex={1}
                                            />
                                            <ImageWithError
                                                {...opacityElementDefaultProps}
                                                src={sample.transparent.model_image_url}
                                            />
                                        </HStack>
                                        : <ImageWithError src={sample.image_url} />
                                }

                                {
                                    sample.model_identity && <ModelIdentityBanner
                                        modelHeight={sample.model_identity.height}
                                        modelSizes={[sample.model_identity.garment_size, sample.model_identity.garment_size2]}
                                    />
                                }
                            </WrapItem>
                        ))}
                </Flex>
            </HStack>
        </VStack>
    );
}
