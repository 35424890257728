import { Button, Flex, Heading, Image, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { EmailField, PasswordField } from '../components/fields';
import UnclosableBanner from '../components/UnclosableBanner';
import { useLoginMutation } from '../services/api/api-auth';
import { ErrorMutation } from '../types/api-types';
import { AUTH_LOCALES, AUTH_TOKEN_KEY, ERROR_LOCALES } from '../utils/constants';
import useCustomNavigate from '../utils/custom-navigate-hook';
import { loadFromLocalStorage } from '../utils/local-storage-helpers';
import { handleLogin } from '../utils/login-helpers';
import useRoles from '../utils/roles-hook';

const Login = () => {
    const [login, { error }] = useLoginMutation();
    const navigate = useCustomNavigate();

    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation([AUTH_LOCALES, ERROR_LOCALES]);
    const { isNoRole, isAdmin } = useRoles();

    // If we were redirected here we should get the pathname of the origin from the URL params
    const from = useMemo(() => {
        const urlSearchParam = new URLSearchParams(location.search);
        const urlFromParam = urlSearchParam.get('from');
        urlSearchParam.delete('from');

        // ---- If no urlFromParam we handle the redirection according to the role ----
        if (!urlFromParam || urlFromParam === '/') {
            if (isNoRole) {
                return `/external_validation?${urlSearchParam.toString()}`;
            }

            return `/?${urlSearchParam.toString()}`;
        }

        return `${urlFromParam}?${urlSearchParam.toString()}`;
    }, [location, isAdmin, isNoRole]);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('email.invalid', { ns: 'errors' }))
            .required(t('required', { ns: 'errors' })),
        password: Yup.string()
            .min(8, t('password.too_short', { ns: 'errors' }))
            .required(t('required', { ns: 'errors' })),
    });

    async function handleSubmit(values: {email: string, password: string}) {
        try {
            const loginResult = await login({ password: values.password, username: values.email }).unwrap();
            handleLogin(dispatch, loginResult);
            navigate(from, { replace: true });
        } catch (e) {
            // Error is handled in the JSX thanks to RTK Error
        }
    }

    // ---- Check if we are already logged in ----
    useEffect(() => {
        const authToken = loadFromLocalStorage(AUTH_TOKEN_KEY);
        if (authToken) {
            navigate(from, { replace: true });
        }
    }, []);

    return (
        <>
            <Flex boxSize="full" minH="520px">
                <VStack justify="center" spacing={8} w={{ base: 'full', lg: '60vw' }}>
                    <Image h="24" src='/assets/illustrations/logo_Veesual-02.svg' w={48}/>
                    <VStack
                        align="flex-start"
                        bg="white"
                        borderRadius="2xl"
                        px={8}
                        py={10}
                        spacing={10}
                        sx={{ '&>form': { w: 'full' } }}
                        w="590px"
                    >
                        <Heading color="black" fontSize="2xl">{t('login.title')}</Heading>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            onSubmit={handleSubmit}
                            validationSchema={LoginSchema}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <VStack spacing={10} w="full">
                                        <VStack align="flex-end" spacing={6} w="full">
                                            {error
                                            && <UnclosableBanner
                                                description={(error as ErrorMutation).data.message || t('unknown', { ns: ERROR_LOCALES })}
                                                title={t('login.title', { ns: ERROR_LOCALES })}
                                                type="error"
                                            />
                                            }
                                            <EmailField label={t('login.email.label')} name="email" placeholder={t('login.email.placeholder')} />
                                            <PasswordField
                                                label={t('login.password.label')}
                                                name="password"
                                                placeholder={t('login.password.placeholder')}
                                            />
                                            <Button
                                                mt="6px !important"
                                                onClick={() => navigate('/forgot-password')}
                                                textDecoration="underline"
                                                variant="link"
                                            >
                                                {t('login.forgot_password_link_label')}
                                            </Button>
                                        </VStack>
                                        <Button isLoading={isSubmitting} type="submit" variant="solid" w="full">{t('login.submit_label')}</Button>
                                    </VStack>
                                </Form>
                            )}
                        </Formik>
                    </VStack>
                </VStack>
            </Flex>
            <Image
                bottom={0}
                boxSize="full"
                display={{ base: 'none', lg: 'block' }}
                fit="contain"
                maxW="35vw"
                pointerEvents="none"
                position="fixed"
                right={0}
                src="/assets/illustrations/heroes.svg"
                top={0}
            />
        </>
    );
};

export default Login;
