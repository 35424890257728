import { Box, HStack, Text, useToast, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import PoseDetailCard from '../../components/cards/PoseDetailCard';
import FeedbackImagesContext from '../../components/FeedbackImagesContext';
import QualityCheckSubHeader from '../../components/headers/QualityCheckSubHeader';
import ImageWithError from '../../components/ImageWithError';
import FeedbackModal from '../../components/modals/FeedbackModal';
import ValidateModal from '../../components/modals/ValidateModal';
import StepSlider from '../../components/StepSlider';
import SuccessLayout from '../../components/SuccessLayout';
import {
    invalidateFeedbackAction,
    useGetGarmentFeedbacksQuery,
} from '../../services/api/api-feedback';
import {
    invalidateGetGarmentsAction,
    useGetGarmentQualityQuery,
    usePutGarmentQualityValidateMutation,
} from '../../services/api/api-garment';
import { usePostMeshMutation } from '../../services/api/api-image';
import { getClient } from '../../services/store/slices/sessionSlice';
import { useAppSelector } from '../../services/store/store';
import { ExperienceTag, FeedbackImage, FeedbackParams, GarmentTag, QcResponse, SingleCutImages } from '../../types/api-types';
import {
    COMMON_LOCALES,
    ERROR_LOCALES,
    FEEDBACK_STATUS,
    MAIN_HEADER_HEIGHT,
    PREFILTERS,
    PREPROCESSING_LOCALES,
    PREPROCESSING_STEPS,
    QCSTEPS,
    SUB_HEADER_HEIGHT,
    WARP_LOCALES,
} from '../../utils/constants';
import useCustomNavigate from '../../utils/custom-navigate-hook';
import useFeedbacks from '../../utils/feedback-hook';
import resizeImage from '../../utils/image';
import useImagePreloader, { getImagePreloadListFromGarmentQuality } from '../../utils/image-preloader-hook';
import { getGarmentQcStepName } from '../../utils/steps-helpers';
import getGarmentPrimaryString from '../../utils/string-helper';
import FullScreenGrid from './FullScreenGrid';

// Full height minus both header and margin
const FINAL_IMAGE_HEIGHT = `calc(100vh - ${SUB_HEADER_HEIGHT}px - ${MAIN_HEADER_HEIGHT}px - 2rem)`;

export default function PreprocessingQC() {
    // ---- Utils Hooks ----
    const { t } = useTranslation([PREPROCESSING_LOCALES, COMMON_LOCALES, WARP_LOCALES]);
    const [searchParams, setSearchParams] = useSearchParams();
    const { search } = useLocation();
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useCustomNavigate();
    const { sendGarmentFeedback } = useFeedbacks();
    const { garmentId } = useParams();

    // ---- States ----
    const [stepsArray, setStepsArray] = useState<string[]>(PREPROCESSING_STEPS);
    const [step, setStep] = useState<number>(0);
    const [stepString, setStepString] = useState<string>(PREPROCESSING_STEPS[0]);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
    const [validateModalOpen, setValidateModalOpen] = useState<boolean>(false);
    const [currentFeedbackContent, setCurrentFeedbackContent] = useState<string>();
    const [feedbackImages, setFeedbackImages] = useState<FeedbackImage[]>([]);
    const [gridFeedbackImages, setGridFeedbackImages] = useState<FeedbackImage[]>([]);
    const [showSuccessScreen, setShowSuccessScreen] = useState<boolean>(false);

    // ---- Store ----
    const currentClient = useAppSelector((state) => getClient(state));

    // ---- APIs ----
    const { data: garmentDetail, error: errorGarmentDetail, isLoading: isGarmentDetailLoading } = useGetGarmentQualityQuery(
        { clientId: currentClient?.id || '', id: garmentId || '' },
        // refetchOnMountOrArgChange is needed if we come back after having validated the garment
        { refetchOnMountOrArgChange: true, skip: !currentClient || !garmentId },
    );
    const [postMesh, { data: meshData, isLoading: isMeshLoading }] = usePostMeshMutation();
    const { data: feedBacks } = useGetGarmentFeedbacksQuery(
        { clientId: currentClient?.id || '', id: garmentId || '', sort: true },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !garmentId || !garmentDetail },
    );
    // ---- Need unsorted feedbacks to show in feedback modal ----
    const { data: unsortedFeedBacks } = useGetGarmentFeedbacksQuery(
        { clientId: currentClient?.id || '', id: garmentId || '' },
        { refetchOnMountOrArgChange: true, skip: !currentClient || !garmentId || !garmentDetail },
    );
    const [putGarmentQualityValidate, { isLoading: isValidationLoading }] = usePutGarmentQualityValidateMutation();

    // ---- Image list for preload in useMemo to prevent preloading for nothing ----
    const imageList = useMemo(
        () => getImagePreloadListFromGarmentQuality(garmentDetail), [garmentDetail],
    );

    // ---- Preloads all images ----
    useImagePreloader(imageList);

    // ---- Vars ----
    const stepName = getGarmentQcStepName(stepString);

    const handleFeedbackSuccess = (response: {
        errors?: {
            [key: string]: string[];
        } | undefined;
        success: boolean;
        'feedback_id'?: string | undefined;
    }, message: string) => {
        setFeedbackModalOpen(false);

        /**
         * We invalidate the garments and crosswarp so we have the updated version in the list (if elesticsearch is fast enough)
         */
        dispatch(invalidateGetGarmentsAction);
        dispatch(invalidateFeedbackAction);

        setCurrentFeedbackContent(message);
    };

    const sendFeedback = (params: FeedbackParams) => new Promise((resolve: (returnValue: void) => void) => {
        if (currentClient && garmentId) {
            resolve();

            return sendGarmentFeedback({ ...params, garmentId, stepString }, handleFeedbackSuccess);
        }

        return null;
    });

    const validateQC = () => {
        // ---- Send the validate PUT request ----
        putGarmentQualityValidate({ clientId: currentClient?.id || '', id: garmentId || '' }).unwrap()
            .then((response) => {
                if (response.success === false) {
                    toast({
                        isClosable: true,
                        status: 'error',
                        title: t('cant_validate_status', { ns: ERROR_LOCALES }),
                    });
                }

                // ---- Put success + success in the response ----
                if (response.success) {
                    setValidateModalOpen(false);
                    setShowSuccessScreen(true);
                    // ---- We invalidate the garments so we have the updated garment in the list (if elesticsearch is fast enough) ----
                    dispatch(invalidateGetGarmentsAction);
                }
            })
            .catch((e) => {
                // ---- Unexpected Request Error ----
                toast({
                    isClosable: true,
                    status: 'error',
                    title: e.data.message,
                });
            });
    };

    const changeStep = (newStep: string) => {
        // ---- Reset feedbackImages ----
        setFeedbackImages([]);
        setGridFeedbackImages([]);

        // ---- This is not handled so we do nothing ----
        if (!stepsArray.includes(newStep)) {
            return;
        }

        const foundStepIndex = stepsArray.findIndex((localStep) => localStep === newStep);

        // ---- We set the new step Value in URL and change the local stepValue ----
        const stepBefore = searchParams.get('step');
        searchParams.set('step', newStep);
        setSearchParams(searchParams, { replace: !stepBefore });
        setStep(foundStepIndex);
        setStepString(newStep);

        // ---- remove success screen when going back a step after showing success screen ----
        setShowSuccessScreen(false);
    };

    const changeStepIndex = (stepValue: number) => {
        changeStep(stepsArray[stepValue]);
    };

    const getDetailDataName = (detailData: GarmentTag | ExperienceTag) => {
        if ((detailData as GarmentTag).tag_name) {
            return (detailData as GarmentTag).tag_name;
        }

        if ((detailData as ExperienceTag).experience_name) {
            return (detailData as ExperienceTag).experience_name;
        }

        return '';
    };

    const stepHrefs = useMemo(() => stepsArray.map(
        ((localStep) => `/preprocessing/${garmentId}/quality${search.replace(/&step=\w*/, `&step=${localStep}`)}`),
    ), [search, stepsArray]);

    const cutImages = useMemo(() => {
        if (!garmentDetail) {
            return undefined;
        }

        if (!garmentDetail.steps.cuts || Array.isArray(garmentDetail.steps.cuts)) {
            return undefined;
        }

        const result: string[] = [];

        Object.values(garmentDetail.steps.cuts).forEach(
            (cutsValue: SingleCutImages) => {
                result.push(cutsValue.image_url);
            },
        );

        return result;
    }, [garmentDetail]);

    const cutContrastImages = useMemo(() => {
        if (!garmentDetail) {
            return undefined;
        }

        if (!garmentDetail.steps.cuts || Array.isArray(garmentDetail.steps.cuts)) {
            return undefined;
        }

        const result: string[] = [];

        Object.values(garmentDetail.steps.cuts).forEach(
            (cutsValue: SingleCutImages) => {
                if (cutsValue.image_contrast_url) {
                    result.push(cutsValue.image_contrast_url);
                }
            },
        );

        return result;
    }, [garmentDetail]);

    const cutMaskImages = useMemo(() => {
        if (!garmentDetail) {
            return undefined;
        }

        if (!garmentDetail.steps.cuts || Array.isArray(garmentDetail.steps.cuts)) {
            return undefined;
        }

        const result: string[] = [];

        Object.values(garmentDetail.steps.cuts).forEach(
            (cutsValue: SingleCutImages) => {
                if (cutsValue.image_opening_url) {
                    result.push(cutsValue.image_opening_url);
                }
            },
        );

        return result;
    }, [garmentDetail]);

    const gridObjects = useMemo(
        () => {
            if (!garmentDetail) {
                return [];
            }

            // ---- Variable used for default value for torso if we have any ----
            let torsoIndex = -1;

            // ---- Mesh Img var ----
            let meshImgData: QcResponse | undefined;

            // ---- Detail vars ----
            const detailKeys = [
                'garment_name',
                'garment_gender',
                'garment_pose',
                'garment_type',
                'garment_category',
                'garment_primary',
                'garment_tags',
                'garment_experiences',
            ] as const;
            const defaultIndex: number[] = [];
            const detailWarnings: string[] = [];
            let warningGenre = false;
            let warningType = false;

            switch (stepString) {
                case QCSTEPS.CLIPPING:
                    return [
                        { imageSrc: garmentDetail.steps.clipping.image_url, label: t('qc_steps.clipping', { ns: COMMON_LOCALES }) },
                        {
                            imageSrc: garmentDetail.image_url,
                            label: t('garments.original'),
                            superpose: garmentDetail.steps.clipping.image_url,
                        },
                        { imageSrc: garmentDetail.steps.clipping.image_contrast_url, label: t('garments.contrast_view') },
                    ];
                case QCSTEPS.FOREGROUND:
                    if (!garmentDetail.steps.foreground || Array.isArray(garmentDetail.steps.foreground)) {
                        return [];
                    }

                    return [
                        { imageSrc: garmentDetail.steps.foreground.image_url, label: t('qc_steps.foreground', { ns: COMMON_LOCALES }) },
                        {
                            imageSrc: garmentDetail.steps.clipping.image_url,
                            label: t('qc_steps.clipping', { ns: COMMON_LOCALES }),
                            superpose: garmentDetail.steps.foreground.image_url,
                        },
                        { imageSrc: garmentDetail.steps.foreground.image_contrast_url, label: t('garments.contrast_view') },
                    ];
                case QCSTEPS.CUT:
                    if (!garmentDetail.steps.cuts || !cutImages || !garmentDetail.steps.foreground) {
                        return [];
                    }

                    torsoIndex = Object.keys(garmentDetail.steps.cuts).findIndex((objKey) => objKey === 'torso');

                    return [
                        {
                            defaultButton: torsoIndex !== -1 ? torsoIndex : undefined,
                            imageSrc: cutImages,
                            label: t('qc_steps.cut', { ns: COMMON_LOCALES }),
                            sideButtons: Object.keys(garmentDetail.steps.cuts),
                        },
                        {
                            defaultButton: torsoIndex !== -1 ? torsoIndex : undefined,
                            imageSrc: cutContrastImages,
                            label: t('garments.contrast_view'),
                            sideButtons: Object.keys(garmentDetail.steps.cuts),
                            superpose: garmentDetail.steps.foreground.image_url,
                        },
                    ];
                case QCSTEPS.MASK:
                    if (!garmentDetail.steps.cuts || !cutImages || !garmentDetail.steps.foreground) {
                        return [];
                    }

                    return [
                        {
                            imageSrc: cutImages,
                            label: t('qc_steps.mask', { ns: COMMON_LOCALES }),
                            // ---- If there is an undefined in the array we show nothing ----
                            overlayImages: cutMaskImages,
                            sideButtons: Object.keys(garmentDetail.steps.cuts),
                            superpose: garmentDetail.steps.foreground.image_url,
                        },
                    ];
                case QCSTEPS.MESH:
                    if (!garmentDetail.steps.mesh || Array.isArray(garmentDetail.steps.mesh)) {
                        return [];
                    }

                    // ---- We show gamrentDetail mesh value if it exists while the mesh request is loading ----
                    if (isMeshLoading && garmentDetail.steps.mesh) {
                        return [
                            {
                                imageSrc: garmentDetail.steps.mesh.image_url,
                                label: t('qc_steps.mesh', { ns: COMMON_LOCALES }),
                            },
                        ];
                    }

                    if (isMeshLoading) {
                        return [];
                    }

                    meshImgData = meshData?.response.find((data) => data.type === 'image');

                    return [
                        {
                            dataSrc: garmentDetail.steps.mesh.image_url,
                            imageSrc: meshImgData
                                ? `data:image/png;base64, ${meshImgData.data}` : garmentDetail.steps.mesh.image_url,
                            label: t('qc_steps.mesh', { ns: COMMON_LOCALES }),
                        },
                    ];
                case QCSTEPS.DETAIL:
                    // ---- Add default index (corresponding to the order in the collapse array) if we have elements in it ----
                    if (garmentDetail.poses.length > 0) {
                        defaultIndex.push(0);
                    }
                    if (garmentDetail.variants && garmentDetail.variants.length > 0) {
                        defaultIndex.push(1);
                    }

                    // ---- Handle Detail step warnings ----
                    // ---- Nb Pose warning ----
                    if (garmentDetail.poses.length !== 1) {
                        detailWarnings.push(t('warnings.details_nb_pose', { ns: COMMON_LOCALES }));
                    }

                    // ---- Same pose warning ----
                    if (garmentDetail.poses.length > 0
                        && garmentDetail.garment_pose
                        && garmentDetail.garment_pose === garmentDetail.poses[0].garment_pose) {
                        detailWarnings.push(t('warnings.details_same_pose', { ns: COMMON_LOCALES }));
                    }

                    // ---- Genre and Type Warnings ----
                    garmentDetail.poses.forEach((pose) => {
                        if (pose.garment_gender !== garmentDetail.garment_gender) {
                            warningGenre = true;
                        }

                        if (pose.garment_type !== garmentDetail.garment_type) {
                            warningType = true;
                        }
                    });
                    garmentDetail.variants?.forEach((pose) => {
                        if (pose.garment_gender !== garmentDetail.garment_gender) {
                            warningGenre = true;
                        }
                        if (pose.garment_type !== garmentDetail.garment_type) {
                            warningType = true;
                        }
                    });

                    if (warningGenre) {
                        detailWarnings.push(t('warnings.details_gender', { ns: COMMON_LOCALES }));
                    }

                    if (warningType) {
                        detailWarnings.push(t('warnings.details_type', { ns: COMMON_LOCALES }));
                    }

                    return [
                        {
                            bottomElement: <VStack spacing={1} w="100%">
                                {
                                    detailKeys.map((detailKey) => {
                                        if (['garment_tags', 'garment_experiences'].includes(detailKey)) {
                                            return <HStack
                                                alignItems="flex-start"
                                                justifyContent="space-between"
                                                key={detailKey}
                                                pl={4}
                                                pr={4}
                                                w="100%"
                                            >
                                                <Text fontWeight="bold">{t(`garments.details.${detailKey}`)}:</Text>
                                                <VStack alignItems="flex-end">
                                                    {(garmentDetail[detailKey] as ExperienceTag[] | GarmentTag[])?.map(
                                                        (tag, i) => <Text color="black" key={i} textAlign="right" wordBreak="break-all">
                                                            {getDetailDataName(tag)}
                                                        </Text>,
                                                    )}
                                                </VStack>
                                            </HStack>;
                                        }

                                        return <HStack justifyContent="space-between" key={detailKey} pl={4} pr={4} w="100%">
                                            <Text
                                                fontWeight="bold"
                                                textAlign="right"
                                            >
                                                {t(`garments.details.${detailKey}`)}:
                                            </Text>
                                            <Text
                                                color="black"
                                                wordBreak="break-all"
                                            >
                                                {
                                                    (detailKey === 'garment_primary')
                                                        ? getGarmentPrimaryString(garmentDetail[detailKey])
                                                        : (garmentDetail[detailKey] as string | number | undefined)
                                                }
                                            </Text>
                                        </HStack>;
                                    })
                                }
                            </VStack>,
                            // ---- Need empty object so FullScreenGrid use the WarpDetailGridElement component ----
                            garmentDetail: {},
                            imageSrc: garmentDetail.image_url,
                            label: t('qc_steps.detail', { ns: COMMON_LOCALES }),
                        },
                        {
                            collapse: [
                                {
                                    content: <Wrap
                                        minH={garmentDetail.poses.length > 0 ? '125px' : undefined}
                                        spacing={4}
                                    >
                                        {garmentDetail.poses && garmentDetail.poses.map(
                                            (pose, index) => <WrapItem
                                                h="100%"
                                                justifyContent="space-between"
                                                key={pose.garment_id + index}
                                                w="calc(33% - 16px)"
                                            >
                                                <PoseDetailCard pose={pose} />
                                            </WrapItem>,
                                        )}
                                    </Wrap>,
                                    count: garmentDetail.poses.length,
                                    title: t('other_poses', { ns: COMMON_LOCALES }),
                                },
                                {
                                    content: <Wrap
                                        minH={garmentDetail.variants && garmentDetail.variants.length > 0 ? '125px' : undefined}
                                        spacing={4}
                                    >
                                        {garmentDetail.variants && garmentDetail.variants.map(
                                            (pose, index) => <WrapItem
                                                h="100%"
                                                justifyContent="space-between"
                                                key={pose.garment_id + index}
                                                w="calc(33% - 16px)"
                                            >
                                                <PoseDetailCard pose={pose} />
                                            </WrapItem>,
                                        )}
                                    </Wrap>,
                                    count: garmentDetail.variants?.length,
                                    title: t('other_variants', { ns: COMMON_LOCALES }),
                                },
                                {
                                    content: <Wrap minH={garmentDetail.extras && garmentDetail.extras.length > 0 ? '250px' : undefined}>
                                        {garmentDetail.extras && garmentDetail.extras.map(
                                            (src, index) => <WrapItem h="100%" justifyContent="space-between" key={src + index} w="calc(25% - 8px)">
                                                <ImageWithError h="100%" maxH="250px" src={src} />
                                            </WrapItem>,
                                        )}
                                    </Wrap>,
                                    count: garmentDetail.extras?.length,
                                    title: t('extra', { ns: COMMON_LOCALES }),
                                },
                            ],
                            defaultIndex,
                            fraction: '3fr',
                            label: t('qc_steps.detail', { ns: COMMON_LOCALES }),
                            warnings: detailWarnings,
                        },
                    ];
                case QCSTEPS.TRANSPARENCY:
                    if (!garmentDetail.steps.transparency || Array.isArray(garmentDetail.steps.transparency)) {
                        return [];
                    }

                    return [
                        {
                            imageSrc: garmentDetail.image_url,
                            label: t('garments.original'),
                            superpose: garmentDetail.steps.transparency.image_url,
                        },
                        {
                            containerStyle: {
                                // eslint-disable-next-line max-len
                                backgroundImage: 'repeating-linear-gradient(45deg, #d2d2d2 25%, transparent 25%, transparent 75%, #d2d2d2 75%, #d2d2d2), repeating-linear-gradient(45deg, #d2d2d2 25%, #ffffff 25%, #ffffff 75%, #d2d2d2 75%, #d2d2d2)',
                                backgroundPosition: '0 0, 10px 10px',
                                backgroundSize: '20px 20px',
                                opacity: 0.8,
                            },
                            imageSrc: garmentDetail.steps.transparency.image_url,
                            label: t('qc_steps.transparency', { ns: COMMON_LOCALES }),
                        },
                        { imageSrc: garmentDetail.steps.transparency.image_contrast_url, label: t('garments.contrast_view') },
                    ];
                default:
                    return [];
            }
        },
        [stepString, garmentDetail, cutImages, cutContrastImages, cutMaskImages, meshData],
    );

    // ---- we allow to go directly to a step or not according to garmentDetail ----
    const allowToStep = useMemo(() => {
        if (
            garmentDetail?.garment_status
            && ([PREFILTERS.TOWARP, PREFILTERS.WARPQUALITY, PREFILTERS.READY] as string[]).includes(garmentDetail?.garment_status)
        ) {
            return stepsArray.length;
        }

        return undefined;
    }, [garmentDetail, stepsArray]);

    const localizedSteps = stepsArray.map((stepKey) => t(`qc_steps.${stepKey}`, { ns: COMMON_LOCALES }));

    const noValidation = useMemo(() => !!((garmentDetail?.garment_status !== PREFILTERS.TOQUALITY)
        && stepsArray.findIndex((localStep) => localStep === stepString) === stepsArray.length - 1),
    [garmentDetail, stepString, stepsArray]);

    const noValidationInfo = useMemo(() => {
        if (!noValidation) {
            return undefined;
        }

        switch (garmentDetail?.garment_status) {
            case PREFILTERS.HASFEEDBACK:
                return t('no_validation_when_feedback', { ns: ERROR_LOCALES });
            case PREFILTERS.READY:
                return t('already_processed', { ns: ERROR_LOCALES });
            default:
                return undefined;
        }
    }, [garmentDetail, noValidation]);

    const isValidationStep = useMemo(() => stepsArray.findIndex(
        (localStep) => localStep === stepString,
    ) === stepsArray.length - 1,
    [stepString, stepsArray]);

    const showBackToList = useMemo(
        () => !!((isValidationStep && noValidation)), [isValidationStep, noValidation],
    );

    const handleAddFeedbackImage = (newImage: FeedbackImage) => {
        setFeedbackImages((prev) => {
            const foundIndex = prev.findIndex((img) => img.src === newImage.src);
            if (foundIndex !== -1) {
                return prev.map((oldImg, index) => {
                    if (foundIndex === index) {
                        return newImage;
                    }

                    return oldImg;
                });
            }

            return [...prev, newImage];
        });
    };

    const handleChangeGridFeedbackImages = (newImages: FeedbackImage[]) => {
        setGridFeedbackImages(newImages);
    };
    const currentWarnings = useMemo(() => {
        switch (stepString) {
            case QCSTEPS.MESH:
                return meshData?.response.filter((data) => data.type === 'warning');
            default:
                return undefined;
        }
    }, [stepString, meshData, garmentDetail]);

    useEffect(() => {
        const stepUrl = searchParams.get('step');
        if (!stepUrl) {
            changeStep(stepsArray[0]);

            return;
        }

        if (garmentDetail?.steps.mesh?.qc_payload) {
            postMesh(garmentDetail?.steps.mesh?.qc_payload);
        }

        setStepString(stepsArray.includes(stepUrl) ? stepUrl : stepsArray[0]);
        setStep(stepsArray.findIndex((localStep) => localStep === stepUrl));
    }, [garmentDetail]);

    // ---- Allows to use back from navigator to change steps ----
    useEffect(() => {
        // ---- Prevents to call this at the init of component ----
        if (!garmentDetail || garmentDetail.garment_id !== garmentId) {
            return;
        }

        const stepUrl = searchParams.get('step');
        const stepIndex = stepsArray.findIndex((localStep) => localStep === stepUrl);

        // ---- Only change step if we found a step and the step is not the current one ----
        if (stepUrl && stepIndex !== -1 && (stepString !== stepUrl || stepIndex !== step)) {
            setStepString(stepsArray.includes(stepUrl) ? stepUrl : stepsArray[0]);
            setStep(stepsArray.findIndex((localStep) => localStep === stepUrl));
            setShowSuccessScreen(false);
        }

        // ---- If the step from URL doesn't exist in the array of steps we go the first one ----
        if (stepUrl && !stepsArray.includes(stepUrl)) {
            changeStep(stepsArray[0]);
            setShowSuccessScreen(false);
        }
    }, [searchParams, stepsArray]);

    useEffect(() => {
        if (errorGarmentDetail) {
            navigate('/preprocessing');
        }
    }, [errorGarmentDetail]);

    // ---- Corrects the steps according to garmentDetail value ----
    useEffect(() => {
        if (garmentDetail) {
            // ---- Steps Update from garmentDetail ----
            const indexToRemove: number[] = [];

            // ---- Foreground Step ----
            if (!garmentDetail.steps.foreground || Array.isArray(garmentDetail.steps.foreground)) {
                const foundIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.FOREGROUND);

                if (foundIndex !== -1) {
                    indexToRemove.push(foundIndex);
                }
            }

            // ---- Cut and Mask Steps ----
            if (!garmentDetail.steps.cuts || !cutImages || !garmentDetail.steps.foreground) {
                const foundCutIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.CUT);
                const foundMaskIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.MASK);

                if (foundCutIndex !== -1) {
                    indexToRemove.push(foundCutIndex);
                }

                if (foundMaskIndex !== -1) {
                    indexToRemove.push(foundMaskIndex);
                }
            }

            // ---- Mesh Step ----
            if (!garmentDetail.steps.mesh || Array.isArray(garmentDetail.steps.mesh)) {
                const foundIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.MESH);

                if (foundIndex !== -1) {
                    indexToRemove.push(foundIndex);
                }
            }

            // ---- Transparency Step ----
            if (!garmentDetail.steps.transparency || Array.isArray(garmentDetail.steps.transparency)) {
                const foundIndex = stepsArray.findIndex((localStep) => localStep === QCSTEPS.TRANSPARENCY);

                if (foundIndex !== -1) {
                    indexToRemove.push(foundIndex);
                }
            }

            // ---- If we have any index to remove ----
            if (indexToRemove.length > 0) {
                // ---- Order the array so the index stay the same after splice ----
                indexToRemove.sort((a, b) => (a < b ? 1 : -1));

                // ---- Need to clone the data to splice ----
                const cloneStepsArray = JSON.parse(JSON.stringify(stepsArray));
                indexToRemove.forEach((removeIndex) => {
                    cloneStepsArray.splice(removeIndex, 1);
                });

                // ---- Asign the new step array ----
                setStepsArray(cloneStepsArray);
            }
        }
    }, [garmentDetail, feedBacks, cutImages]);

    return (
        <Box height='100%'>
            {
                !errorGarmentDetail
                && <>
                    <FeedbackModal
                        history={unsortedFeedBacks?.items.filter((feedback) => feedback.status !== FEEDBACK_STATUS.CANCELED)}
                        images={[...feedbackImages, ...gridFeedbackImages]}
                        initialInput={currentFeedbackContent}
                        modalOpen={feedbackModalOpen}
                        onClose={() => { setFeedbackModalOpen(false); }}
                        onSend={sendFeedback}
                        stepDetail={`${step} - ${stepName}`}
                    />
                    <ValidateModal
                        modalOpen={validateModalOpen}
                        onClose={() => { setValidateModalOpen(false); }}
                        onValidate={validateQC}
                    />
                    <QualityCheckSubHeader
                        feedbackClick={() => setFeedbackModalOpen(true)}
                        garmentQuality={garmentDetail}
                        hasFeedbackLink={
                            (feedBacks && feedBacks.items.length !== 0 && garmentId)
                                ? `/garment/${garmentId}/feedbacks`
                                : undefined
                        }
                        incrementStep={
                            () => {
                                if (isValidationStep) {
                                    return setValidateModalOpen(true);
                                }

                                return changeStep(stepsArray[stepsArray.findIndex((localStep) => localStep === stepString) + 1]);
                            }}
                        isLoading={isGarmentDetailLoading}
                        isValidated={showSuccessScreen}
                        lastFeedback={(feedBacks && feedBacks.items.length !== 0) ? feedBacks.items[0] : undefined}
                        nextButtonLabel={isValidationStep ? t('validate') : t('next_step')
                        }
                        noValidation={noValidation}
                        noValidationInfo={noValidationInfo}
                        showBackToList={showBackToList}
                        title={t('garments.title')}
                        validationLoading={isValidationLoading}
                    >
                        <StepSlider
                            allowToStep={allowToStep}
                            changeValue={changeStepIndex}
                            hrefs={stepHrefs}
                            labels={localizedSteps}
                            max={stepsArray.length - 1}
                            min={0}
                            step={1}
                            value={step}
                        />
                    </QualityCheckSubHeader>
                    {
                        searchParams.get('mode') === 'garment' && <>
                            {(showSuccessScreen && garmentDetail)
                                ? <HStack h={FINAL_IMAGE_HEIGHT} justifyContent="center">
                                    <SuccessLayout
                                        imgSrc={resizeImage(garmentDetail.image_url, { width: 800 })}
                                        text={t('final_step_explanation')}
                                    />
                                </HStack>
                                : <FeedbackImagesContext.Provider
                                    value={{
                                        addFeedbackImage: handleAddFeedbackImage,
                                        changeGridFeedbackImages: handleChangeGridFeedbackImages,
                                        feedbackImages,
                                        gridFeedbackImages,
                                    }}
                                >
                                    <FullScreenGrid
                                        gridObjects={gridObjects}
                                        loading={(stepString === QCSTEPS.MESH && isMeshLoading && !garmentDetail?.steps.mesh)}
                                        stepString={stepString}
                                        warnings={currentWarnings}
                                        warningsLoading={stepString === QCSTEPS.MESH ? isMeshLoading : false}
                                    />
                                </FeedbackImagesContext.Provider>

                            }

                        </>
                    }
                </>

            }

        </Box>
    );
}
