import { DownloadIcon } from '@chakra-ui/icons';
import { Button, HStack, Switch, Text, VStack } from '@chakra-ui/react';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DashboardDateInput, { formatDateToInput } from '../components/DashboardDateInput';
import DashboardTable from '../components/DashboardTable';
import SearchBar from '../components/SearchBar';
import { useGetDashboardHistoryQuery, useGetDashboardStatesQuery } from '../services/api/api-dashboard';
import { DashboardRowData } from '../types/api-types';
import {
    COMMON_LOCALES,
    LAYOUT_LOCALES,
} from '../utils/constants';
import useRoles from '../utils/roles-hook';

const Home = () => {
    const { t } = useTranslation([LAYOUT_LOCALES, COMMON_LOCALES]);
    const { isAdmin } = useRoles();

    const [showArchive, setShowArchive] = useState<boolean>(false);
    const [toDate, setToDate] = useState<string>();
    const [fromDate, setFromDate] = useState<string>();
    const { data: dashboardState, isFetching: isDashboardLoading } = useGetDashboardStatesQuery(showArchive ? 1 : 0);
    const { data: dashboardTo } = useGetDashboardHistoryQuery(toDate || '', { skip: !toDate || toDate === '' });
    const { data: dashboardFrom } = useGetDashboardHistoryQuery(fromDate || '', { skip: !fromDate || fromDate === '' });

    const [search, setSearch] = useState<string>();

    const tableRef = useRef<{exportHtml(toDate?: string, fromDate?: string): void}>(null);

    const dashboardData = useMemo(() => {
        if (!dashboardState && !dashboardTo) {
            return null;
        }

        const localDashboardState = dashboardTo && toDate ? dashboardTo?.datas : dashboardState;

        const result: DashboardRowData[] = [];
        localDashboardState?.forEach((respState) => {
            if (respState.states.length > 0) {
                result.push({
                    client: respState.client,
                    state: respState.states[0].state,
                    subRows: respState.states.filter((_, index) => index !== 0).map((state) => ({
                        client: respState.client,
                        experience: state.experience,
                        state: state.state,
                    })),
                });
            }
        });

        if (!fromDate || !dashboardFrom) {
            return result;
        }

        dashboardFrom?.datas.forEach((compareState) => {
            if (compareState.states.length > 0) {
                const foundIndex = result.findIndex((resultState) => resultState.client.id === compareState.client.id);
                if (foundIndex === -1) {
                    return;
                }

                result[foundIndex].compareState = compareState.states[0].state;

                result[foundIndex].subRows = result[foundIndex].subRows?.map((resultSubRow) => {
                    const foundState = compareState.states.find((localState) => localState.experience?.id === resultSubRow.experience?.id);
                    if (foundState) {
                        return { ...resultSubRow, compareState: foundState.state };
                    }

                    return resultSubRow;
                });
            }
        });

        return result;
    }, [dashboardState, dashboardTo, dashboardFrom, fromDate, toDate]);

    const handleChangeFromDate = (newDateString?: string) => {
        setFromDate(newDateString);
    };

    // ---- Download an HTML file that can be imported in Excel or Google Sheet ----
    const handleExport = () => {
        let exportToDate = toDate || formatDateToInput(new Date(Date.now()));
        let exportFromDate = fromDate;
        const timezoneOffset = new Date().getTimezoneOffset() * 60000;

        if (dashboardFrom && fromDate) {
            const apiDate = new Date(dashboardFrom.from);

            exportFromDate = formatDateToInput(new Date(apiDate.getTime() + timezoneOffset));
        }

        if (dashboardTo && toDate) {
            const apiDate = new Date(dashboardTo.from);

            exportToDate = formatDateToInput(new Date(apiDate.getTime() + timezoneOffset));
        }

        tableRef.current?.exportHtml(exportToDate, exportFromDate);
    };

    return (
        <VStack alignItems="flex-start" h="100%" spacing={0}>
            <HStack backgroundColor={'gray.200'} borderBottom="1px solid gray" p={4} w="100%">
                <HStack flex={1} spacing={8}>
                    <HStack>
                        <SearchBar
                            debounce={500}
                            onChange={setSearch}
                            placeholder={`${t('filter', { ns: COMMON_LOCALES })} ${t('client')} & ${t('experience')}`}
                        />
                    </HStack>
                    {
                        isAdmin
                        && <HStack>
                            <Text>{t('archived_client', { ns: COMMON_LOCALES })}</Text>
                            <Switch onChange={(e) => setShowArchive(!!e.target.checked)} />
                        </HStack>
                    }

                    <HStack spacing={4}>
                        <DashboardDateInput
                            fullDate={fromDate && dashboardFrom ? dashboardFrom.from.toString() : fromDate}
                            label={t('from', { ns: COMMON_LOCALES })}
                            onChange={handleChangeFromDate}
                        />
                        <DashboardDateInput
                            fullDate={
                                toDate && dashboardTo
                                    ? dashboardTo?.from.toString()
                                    : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toUTCString()
                            }
                            label={t('to', { ns: COMMON_LOCALES })}
                            onChange={setToDate}
                        />
                    </HStack>

                </HStack>
                <Button onClick={handleExport} rightIcon={<DownloadIcon />}>{t('export', { ns: COMMON_LOCALES })}</Button>
            </HStack>

            <DashboardTable
                dashboardData={dashboardData}
                isDashboardLoading={isDashboardLoading}
                ref={tableRef}
                search={search}
            />

        </VStack>
    );
};

export default Home;
