import { Button, HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';

import InfoWithTooltip from '../../../components/InfoWithTooltip';
import useFeedbackContextHelper from '../../../utils/feedback-context-helper-hook';
import GridItemImage from '../GridItemImage';

interface SingleImageGridElementProps {
    label?: string
    info?: string
    imageSrc: string | string[]
    superpose?: string | string[]
    superposeAnimation?: boolean
    overlay?: string[]
    onSuperposeChange?(superposeValue: boolean): void
    superposeLabels?: [string, string],
    TopLeftElement?: React.ReactNode,
    additionalText?: string
    dataSrc?: string
    containerStyle?: StackProps
}

export default function SingleImageGridElement(props: SingleImageGridElementProps) {
    const {
        label,
        info,
        imageSrc,
        superpose,
        overlay,
        superposeAnimation,
        onSuperposeChange,
        superposeLabels,
        TopLeftElement,
        additionalText,
        dataSrc,
        containerStyle,
    } = props;

    useFeedbackContextHelper({
        imageSrc,
        label: superposeLabels ? superposeLabels[0] : label,
        overlay,
        superpose,
        superposeLabel: superposeLabels ? superposeLabels[1] : undefined,
    });

    // ---- Local superpose state and handle needed for the superposeLabels ----
    const [localSuperpose, setLocalSuperpose] = useState<boolean>();
    const handleOnSuperposeChange = (superposeValue: boolean) => {
        setLocalSuperpose(superposeValue);

        if (onSuperposeChange) {
            onSuperposeChange(superposeValue);
        }
    };

    // ---- Local Superpose Lock ----
    const [superposeLockedIndex, setSuperposeLockedIndex] = useState<number>();
    const handleSuperposeLock = (index: number) => {
        setSuperposeLockedIndex(superposeLockedIndex === index ? undefined : index);
    };

    return (
        <VStack h="100%" w="100%">
            {
                label && <HStack justifyContent="space-around" pl={4} pr={4} spacing={4} w="100%">
                    <HStack justifyContent="center">
                        <Text fontWeight="bold">{label}</Text>
                        {info && <InfoWithTooltip tooltipLabel={info} />}
                    </HStack>
                </HStack>
            }
            <HStack
                height="100%"
                justifyContent="center"
                position="relative"
                spacing={0}
                w="100%"
                {...containerStyle}
            >
                <GridItemImage
                    additionalText={additionalText}
                    dataSrc={dataSrc}
                    imageSelected={null}
                    onSuperposeChange={handleOnSuperposeChange}
                    overlay={overlay}
                    overrideSuperposeBoolean={superposeLockedIndex === undefined ? undefined : !!superposeLockedIndex}
                    src={imageSrc}
                    superpose={superpose}
                    superposeAnimation={superposeLockedIndex === undefined && superposeAnimation}
                />
                {
                    superpose && superposeLabels
                    && <HStack
                        bottom={0}
                        color="black"
                        position="absolute"
                        right={4}
                        zIndex={1}
                    >
                        {
                            superposeLabels.map((superposeLabel, index) => <Button
                                isActive={superposeLockedIndex === index}
                                key={superposeLabel + index}
                                onClick={() => handleSuperposeLock(index)}
                                outline={((index === 0 && !localSuperpose) || (index === 1 && localSuperpose)) ? '1px solid' : ''}
                                variant="tab-like"
                                w="100%"
                            >
                                {superposeLabel}
                            </Button>)
                        }

                    </HStack>
                }
                {
                    TopLeftElement
                    && <VStack left={4} position="absolute" top={0}>
                        {TopLeftElement}
                    </VStack>

                }
            </HStack>
        </VStack>
    );
}
